<template>
  <v-dialog
    :value="true"
    fullscreen
    :transition="false"
  >
    <v-container
      fluid
      align-content-center
      class="full-background"
    >
      <div class="is-on-back">
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />
      </div>
      <v-layout
        align-center
        justify-center
        row
        fill-height
      >
        <div class="text-center">
          <v-img
            :src="require('../assets/saturne_logo.png')"
            class="my-3"
            contain
            height="200px"
          />

          <h1 class="font-weight-light display-3 white--text">
            Saturne
            <sup style="font-size: 0.4em; top: -1.2em">6.0</sup>
          </h1>

          <h2 class="font-italic font-weight-regular white--text mt-2 mb-5">
            Gestionnaire d'évènements à ENSTA Paris
          </h2>

          <v-btn
            color="blue"
            depressed
            dark
            large
            @click="login"
          >
            Connexion
          </v-btn>
          <p class="mt-9">
            <span class="font-weight-thin white--text">
              Projet développé par
              <a href="https://data.ensta.fr">DaTA ENSTA</a>
            </span>
          </p>
        </div>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import "@/assets/stars.css";

import { loginCheck } from "@/utils/login";

export default {
  name: "Login",
  props: {
    to: {
      type: String,
      default: "/",
    },
  },
  watch: {
    $route(to) {
      if (to.query.err) {
        this.fail(to.query.err);
      }
    },
  },
  mounted() {
    if (this.$route.query.err) {
      this.fail(this.$route.query.err);
    }
  },
  methods: {
    fail(err) {
      if (err) {
        this.$alert.$emit("snackbar", {
          message: err,
          status: "error",
          close_btn: false,
        });
      }
    },
    login() {
      loginCheck(this.to, (x, err) => {
        if (err) {
          this.fail(err);
        } else {
          this.$router.push(x);
        }
      });
    },
  },
};
</script>

<style scoped>
a,
a:visited {
  text-decoration: none;
  color: #bdbdbd;
}
</style>
